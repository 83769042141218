import React, { Component } from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

import Img from 'gatsby-image'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Section from '../layouts/section'
import Wrapper from '../layouts/wrapper'
import Button from '../layouts/button'

import CallToAction from '../components/call-to-action'

import { DownArrow } from '../svgs/icons'

const ProjectHeader = styled.header`
  position: relative;
  display: flex;
  align-items: center;
  min-height: 100vh;
  overflow: hidden;

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: ${props => props.bgcolor};
    z-index: 1;
    opacity: 0.6;
  }
`

const ProjectHeroImgWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
`

const ProjectHeroImg = styled(Img)`
  position: absolute !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`

const ProjectHeaderContent = styled.article`
  position: relative;
  z-index: 1;
  max-width: 800px;
`

const ProjectHeading = styled.h1`
  margin-bottom: 24px;
  font-size: 1.875rem;
  font-weight: 800;
  line-height: 1.2;
  text-shadow: 0 5px 15px rgba(0,0,0,0.1);
  color: #FFF;

  @media (min-width: 768px) {
    font-size: 2.25rem;
    line-height: 1.167;
  }

  @media (min-width: 1024px) {
    font-size: 4.5rem;
    line-height: 1.083;
  }
`

const ProjectClient = styled.p`
  font-size: 1.5;
  color: #FFF;
`

const ScrollDownButton = styled.a`
  position: absolute;
  bottom: 24px;
  left: 50%;
  z-index: 1;
  transform: translateX(-50%);
  cursor: pointer;
`

const ProjectContent = styled.article`

  > p {
    margin-bottom: 12px;

    @media (min-width: 768px) {
      margin-bottom: 24px;
      margin-left: auto;
      margin-right: auto;
      max-width: 768px;
      font-size: 1.125rem;
      line-height: 1.667;
    }
  }

  > .gatsby-resp-image-wrapper {
    margin-top: 48px;
    margin-bottom: 48px;
  }
`

class Project extends Component {
  constructor(props) {
    super(props)
    this.projectContentRef = React.createRef()
  }

  scrollToMyRef = _ => {
    // window.scrollTo(0, this.projectContentRef.current.offsetTop)
    // window.scrollBy({ 
    //   top: 10, // could be negative value
    //   left: 0, 
    //   behavior: 'smooth' 
    // });
    const HeaderBarHeight = 96
    const contentTopPos = this.projectContentRef.current.offsetTop
    const scrollPosition = contentTopPos - HeaderBarHeight
    
    window.scroll({
      top: scrollPosition, 
      left: 0, 
      behavior: 'smooth'
    });
    //this.projectContentRef.current.scrollIntoView({behavior: 'smooth', block: 'start'})
  }

  render() {
    const { data } = this.props

    const {
      html
    } = data.markdownRemark
  
    const { 
      title,
      client,
      website,
      brandColor,
      heroImg,
      seoTitle
    } = data.markdownRemark.frontmatter
  
    return (
      <Layout location='/work/' headerTheme='light'>
        <SEO title={`${seoTitle} | DesignWeb NZ`} />
        <ProjectHeader bgcolor={brandColor}>
          <ProjectHeroImgWrapper>
            <ProjectHeroImg fluid={heroImg.childImageSharp.fluid} />
          </ProjectHeroImgWrapper>
          <Wrapper>
            <ProjectHeaderContent>
              <ProjectHeading>{title}</ProjectHeading>
              <ProjectClient>{client}</ProjectClient>
            </ProjectHeaderContent>
          </Wrapper>
          <ScrollDownButton onClick={this.scrollToMyRef}>
            <DownArrow />
          </ScrollDownButton>
        </ProjectHeader>
        <Section paddedTop paddedBottom ref={this.projectContentRef}>
          <Wrapper>
            <ProjectContent dangerouslySetInnerHTML={{ __html: html}} />
            {/* <Button mod='primary' to={website}>
              Visit website
            </Button> */}
          </Wrapper>
        </Section>
        <CallToAction />
      </Layout>
    )
  }
}

export default Project

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        client
        website
        brandColor
        seoTitle
        heroImg {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 50) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`